import React, { useState } from 'react'
import { useCookies } from 'react-cookie'

// Components
import Header from './Header'
import Footer from './Footer'
import AgeGate from './AgeGate'

// CSS
import 'bootstrap/dist/css/bootstrap.min.css'
import '../assets/css/styles.min.css'

const Layout = ({children, currentPage, customPage, thankYouPage}) => {
  const [showAgeGate, setShowAgeGate] = useState(false)
  const [cookies, setCookie] = useCookies(['adult'])

  function setAgeValid(){
    setShowAgeGate(!showAgeGate)
    //set cookie
    setCookie('adult', true, [])
  }

  return(
    <>
      {(cookies['adult'] !== "true" && !showAgeGate)?
        <AgeGate setAgeValid={setAgeValid} />
      :
        <>
          {/* Header */}
          <Header customPage={(currentPage)? currentPage : null} />
          {(currentPage)?  <main id={currentPage} className={(thankYouPage)? "thank-you" : null}>{children}</main>
          : <main>{children}</main>
          }
          {/* Footer */}
          <Footer />
        </>
      }
    </>
  )
}

export default Layout